// React Component 
import { Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMetadata, setLeftCount, setTotalCount, setMintBalance, setWlBalance, } from "../../../store/reducers/connectionReducer";

// Wallet Component 
import { Account, Near } from "near-api-js";
import { nftMintFromContract } from "../../../utilities/contract";

// Page Component 
import Bg from "../../../assets/img/picture/bg.gif";
import CountDown from "../../../components/Elements/CountDown";
import Loader from "../../../assets/img/logo/loader.gif";
import ProgressBar from "../../../components/Elements/ProgressBar";
import Twitter from "../../../assets/img/picture/twitter.png";
import Discord from "../../../assets/img/picture/discord.png";
import LeftEar from "../../../assets/img/picture/leftear.png";
import RightEar from "../../../assets/img/picture/rightear.png";
import Hair from "../../../assets/img/picture/hair.png";

export default function Content() {

    // Wallet CONST
    const dispatch= useDispatch();
	const wallet = useSelector(state => state.wallet)
    const metadata = useSelector(state => state.metadata)
	const leftCount = useSelector(state => state.leftCount)
	const totalCount = useSelector(state => state.totalCount)
	const wlBalance = useSelector(state => state.wlBalance)
	const nearConfig = useSelector(state => state.nearConfig)
    const contractID = nearConfig.contractName;
    const near = new Near(useSelector(state => state.nearConfig))
    const userAccountId = useSelector(state => state.userAccountId)
    const account = new Account(near.connection, userAccountId);
	const mintBalance = useSelector(state => state.mintBalance)
    const userBalance = useSelector(state => state.userBalance)
	const isWalletConnected = useSelector(state => state.isWalletConnected)
    const [publicMintDate, setPublicMintDate] = useState(null);
    const [whitelistMintDate, setWhitelistMintDate] = useState(null);
    const [isWhitelistMintDate, setIsWhitelistMintDate] = useState(null);
    const [isPublicMintDate, setIsPublicMintDate] = useState(null);

    // Modal 
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState(null);
    const handleModalClose = () => setModal(false);
    
    // Retrieve project data & smart contract details  
	useEffect(async () => {
        const contractAccount = new Account(near.connection, contractID);
		const metadata = await contractAccount.viewFunction(contractID, 'get_metadata');
		const leftCount = await contractAccount.viewFunction(contractID, 'mintlist_count');
		const totalCount = await contractAccount.viewFunction(contractID, 'nft_total_supply');
        let publicDate = new Date(metadata.public_mint_start_time * 1000)
        let whitelistDate = new Date(metadata.wl_mint_start_time * 1000)
        setPublicMintDate(publicDate)
        setWhitelistMintDate(whitelistDate)
        setIsPublicMintDate(publicDate.getTime() <= new Date().getTime())
        setIsWhitelistMintDate(whitelistDate.getTime() <= new Date().getTime())
        dispatch(setMetadata(metadata))
        dispatch(setLeftCount(leftCount))
        dispatch(setTotalCount(parseInt(totalCount)))
        if (isWalletConnected && userAccountId) {
			const wlBalance = await contractAccount.viewFunction(contractID, 'get_whitelist_allowance', {"account_id": userAccountId});
			const mintBalance = await contractAccount.viewFunction(contractID, 'get_mint_allowance', {"account_id": userAccountId});
            dispatch(setWlBalance(wlBalance));
            dispatch(setMintBalance(mintBalance));
		}
	}, [leftCount, userAccountId]);

   
    if(leftCount !== null){
        return (
            <>
                <div id='cloudSection'>
                    <div className="cloud large cloud-1">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud normal cloud-2">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud small cloud-3">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud normal cloud-2">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud small cloud-3">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud tiny cloud-4">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud large cloud-5">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud normal cloud-6">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud small cloud-7">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud tiny cloud-8">
                    <div></div><div></div><div></div><div></div>
                    </div>
                 
                    <div className="cloud tiny cloud-11">
                    <div></div><div></div><div></div><div></div>
                    </div>
                    <div className="cloud small cloud-12">
                    <div></div><div></div><div></div><div></div>
                    </div>
                </div>
                <div className="flexSpaceCenter container" id='launchpadContent'>
                    <div id="rightSide">
                        <div id="imageWrapper">
                            <div id="giffy-images-container">
                                <img className='radius8' src={Bg}  />
                            </div>
                            <ProgressBar progress={parseFloat((totalCount - leftCount) / totalCount * 100).toFixed(2)} minted={totalCount - leftCount} total={totalCount}/>
                        </div>
                    </div>
                    <div id="leftSide">
                        <div>
                            <div id='topSpan'>
                                <span>Doxxed Team</span>
                            </div>
                            <h1 className="bold font60">Bright Bunny</h1>
                            <h2 className="font15 regular" >
                            999 Bunnies ready to make vouchers and eat carrots in the $NEAR ecosystem ! Grab two bunnies to get airdropped a carrot NFT. This collection is based on fund redistribution : 15% of minting revenues will be given to an organisation to help sick childrens. Also, 50% of royalties from Bunnies and Carrots collections will be redistributed to Carrots holders. 
                            </h2>
                            <div className='social-item'>
                                <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://twitter.com/brightbunnynear'><img src={Twitter} alt="twitter"/></a>
                                <a target="_blank" rel="noreferrer" className='topSocial' href='https://t.co/5VN08Wb9U3'><img src={Discord} alt="discord"/></a>
                            </div>
                            <div id='bottomSpan'>
                                <div className='spanItem'>
                                    <label> Items </label>
                                    <span> {totalCount} </span>
                                </div>
                                <div className='spanItem'>
                                    <label> Mint Price </label>
                                    <span> {parseFloat(metadata.mint_price).toFixed(0)} NEAR </span>
                                </div>
                            </div>
                            <div className='btnWrapper'>
                                <button className={isWhitelistMintDate?'btn-primary connectWallet':'btn-primary connectWallet notwl'} id="headerButton" onClick={() => headerBtnHandle()}>
                                    {isWalletConnected ? leftCount <= 0 ?'Mint Out' : (isPublicMintDate?' Mint Now':isWhitelistMintDate?'Whitelist Mint ':'Coming Soon') : 'Connect Wallet'}
                                    <small> {(!isPublicMintDate && isWhitelistMintDate)?(wlBalance>0)?"You're elligible for "+wlBalance+" whitelist mint":'You have reached the max amount of whitelist mint':'' } </small>
                                </button>
                                <CountDown isWhitelist={isWhitelistMintDate} isPublic={isPublicMintDate} whitelist_date={whitelistMintDate} public_date={publicMintDate}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className='responseModal' show={modal} onHide={handleModalClose} >
                    <Modal.Body>
                        <div className='promptBody'>
                            <img src={Hair} id='hair' alt="Hair" />
                            <img src={LeftEar} id='leftEar' alt="LeftEar" />
                            <img src={RightEar} id='rightEar' alt="RightEar" />
                            <p className="font20">{modalMsg}</p>
                            <div className="promptBtnSection">
                                <button className="promptClose" onClick={handleModalClose}> ✕  </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else 
    {
        return (
            <div id="loader">
                <img src={Loader} alt="Loading" />
                <p>Loading...</p>
            </div>
        )
    }


    // FUnction to show moadl 
    function showModal(msg){
        setModalMsg(msg);
        setModal(true);
    }
    
    // Header button handle 
	function headerBtnHandle(){
        
		if (isWalletConnected)
        {
            if(leftCount <= 0)
                showModal('Bright Bunny have been minted out ! Thank you for your support !')
            else 
            {
                if(isPublicMintDate || isWhitelistMintDate){
                    if(!isPublicMintDate && wlBalance<=0)
                        showModal('You have reached the max amount of whitelist mint')
                    else 
                    {
                        nftMintFromContract(wallet.account(), userBalance, 1, wlBalance, mintBalance, metadata, contractID, isWhitelistMintDate, isPublicMintDate).then(function(response){
                            if(response != 'success')
                                showModal(response)
                        });
                    }
                }
                else 
                    showModal('🥕 Coming Soon 🥕');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
	}

}


