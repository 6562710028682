// React Component 
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setWallet, setIsWalletConnected, setUserAccountId, setUserBalance } from "./store/reducers/connectionReducer";

// NEAR Component
import { connect, WalletConnection } from "near-api-js";

// Screen Component 
import Launchpad from './screens/Launchpad';
import { Modal } from 'react-bootstrap';
import LeftEar from "./assets/img/picture/leftear.png";
import RightEar from "./assets/img/picture/rightear.png";
import Hair from "./assets/img/picture/hair.png";


export default function App(props) {

    // Define wallet CONST
	const dispatch= useDispatch()
    const nearConfig = useSelector(state => state.nearConfig)
    const userAccountId = useSelector(state => state.userAccountId)
	const isWalletConnected = useSelector(state => state.isWalletConnected)

    // Hook & update wallet status
    useEffect(async () => {
        const near = await connect(nearConfig);
        const wallet = new WalletConnection(near);
        dispatch(setWallet(wallet));
        dispatch(setIsWalletConnected(wallet.isSignedIn()))
        if (wallet.isSignedIn()){
            dispatch(setUserAccountId(wallet.account().accountId))
            let balance = await wallet.account().getAccountBalance();
            dispatch(setUserBalance(balance))
        }
    }, [isWalletConnected, userAccountId]);


    // Modal 
    const [modal, setModal] = useState(false);
    const handleModalClose = () => setModal(false);
    const [modalErr, setModalErr] = useState(null);

    // Hook callback function 
    useEffect(() => {

        // Function to check if minted
        async function checkResponse() {
            let url = new URL(window.location.href);
            setModalErr(url.searchParams.get('errorMessage'));
            if (url.searchParams.get('mint')) 
                setModal(true);        
        }
        checkResponse();
    }, []);


    return (
        <>
            <Launchpad page='launchpad'/>
            <Modal className='responseModal' show={modal} onHide={handleModalClose} >
                <Modal.Body>
                    <div className='promptBody'>
                        <img src={Hair} id='hair' alt="Hair" />
                        <img src={LeftEar} id='leftEar' alt="LeftEar" />
                        <img src={RightEar} id='rightEar' alt="RightEar" />
                        <p className="font20">{ (modalErr)?decodeURIComponent(modalErr):"Congratulation, you have minted Bright Bunny(s) successfully!" }</p>
                        <div className="promptBtnSection">
                            <button className="promptClose" onClick={handleModalClose}> ✕  </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );

}


