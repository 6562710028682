import {Contract} from 'near-api-js';
import * as nearAPI from 'near-api-js';

export const {
	utils: {
		format: {
			formatNearAmount, parseNearAmount
		}
	}
} = nearAPI;


export const nftMintFromContract = async (account, deposit, quantity, wlBalance, mintBalance, metadata, contractID, isWhitelistMint, isPublicMint) => {

        
    // Check if user have reach max mint per wallet 
    if(metadata.max_mint_per_wallet - mintBalance < quantity)
        return "Max mint per wallet is " + metadata.max_mint_per_wallet;

	// If is public mint 
	let amount = 0;
	if(isPublicMint) {

        // Get mint amount 
		let amount_near = metadata.mint_price * quantity;
		amount = parseNearAmount(amount_near.toString());

	} 
    // If is whitelist mint 
    else 
    {
		// Check whitelist access
		if(wlBalance < quantity)
			return "You have reached the max amount of whitelist mint";

        // Get mint amount 
		let amount_near = metadata.wl_mint_price * quantity;
		amount = parseNearAmount(amount_near.toString());
		
	}
        
    // Validate account balance
    let checkAmount = parseFloat(amount) / 1000000000000000000000000;
    let checkDeposit = parseFloat(deposit.available) / 1000000000000000000000000;
    if(checkAmount > checkDeposit) 
        return "Acccount balance not enough";

	// Mint
	let gas = (25000000000000 * (quantity + 1)).toString();
    const nftContract = new Contract(
        account,
        contractID,
        {
            changeMethods: ['nft_mint_from_contract'],
            sender: account,
        }
    );
    await nftContract.nft_mint_from_contract({
        callbackUrl: 'https://brightbunny.enleap.app/?mint=true&',
        args: {
            receiver_id: account.accountId,
            quantity,
        },
        gas: gas,
        amount: amount
    });
    
    return 'success';

};