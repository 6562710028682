import React from "react";
import styled from "styled-components";

export default function ProgressBar({progress, minted, total}) {
  return (
    <Progress className="flex flexColumn">
        <div className="progressTitle">
            <h2> TOKENS MINTED </h2>
            <span>{progress}%</span>
        </div>
        <div className="progress">
            <span className="progress-bar" style={{ width: `${progress}%`}}></span>
        </div>
         <div className="progressFooter">
            <span>{minted}/{total}</span>
        </div>
    </Progress>
  );
}

const Progress = styled.div`
  width: 100%;
`;