import React from "react";
import '../style/launchpad.css';
import store from "../store/store.js";
import { Provider } from "react-redux";
import TopNavbar from "../components/Nav/TopNavbar";
import Content from "../components/Sections/Launchpad/Content";
import Footer from "../components/Nav/Footer"

export default function Launchpad(props) {
  return (
    <>
        <Provider store={store}>
            <TopNavbar page={props.page} />
            <Content page={props.page} />
            <Footer/>
        </Provider>
    </>
  );
}


