
import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Enleap from "../../assets/img/logo/enleap.png";

export default function Contact() {

    // Get current year
    const getCurrentYear = () => { return new Date().getFullYear();}

    return (
        <Wrapper className="wrapper">
            <div className="darkBg">
                <div className="container">
                <InnerWrapper className="flexSpaceCenter innerWrapper footer" style={{ padding: "30px 0" , justifyContent: "flex-end"}}>
                    <StyleP className="whiteColor font16">
                       Powered by - <span className="purpleColor font16">Enleap <img id='enleapLogo' src={Enleap} alt="Enleap"/></span> 
                    </StyleP>
                </InnerWrapper>
                </div>
            </div>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;