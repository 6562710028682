// React Component 
import React, {  useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux'
import { setIsWalletConnected} from "../../store/reducers/connectionReducer";

// Page Component
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import Twitter from "../../assets/img/picture/twitter.png";
import Discord from "../../assets/img/picture/discord.png";

export default function TopNavbar(props) {

    // Wallet & scroll CONST
	const [y, setY] = useState(window.scrollY);
	const [sidebarOpen, toggleSidebar] = useState(false);
	const dispatch= useDispatch()
	const wallet = useSelector(state => state.wallet)
	const nearConfig = useSelector(state => state.nearConfig)
	const isWalletConnected = useSelector(state => state.isWalletConnected)

    // Detect scroll 
	useEffect(() => {
		window.addEventListener("scroll", () => setY(window.scrollY));
		return () => {
			window.removeEventListener("scroll", () => setY(window.scrollY));
		};
	}, [y]);


	return (
		<>
			<Wrapper className="flexCenter animate" id='topNav' style={y > 100 ? { height: "60px" } : { height: "80px", position:"absolute" }}>
				<NavInner className="container flexSpaceCenter">
					<a className="pointer flexNullCenter" href='/'></a>
					<UlWrapperRight className="flexNullCenter">
                        <li className="semiBold font15 pointer">
                            <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://twitter.com/brightbunnynear'><img src={Twitter} alt="twitter"/></a>
                        </li>
                        <li className="semiBold font15 pointer">
                            <a target="_blank" rel="noreferrer" className='topSocial' href='https://t.co/5VN08Wb9U3'><img src={Discord} alt="discord"/></a>
                        </li>
						<li className="bold font15 pointer flexCenter ">
							<a className="radius8 signOutButton" style={{display:isWalletConnected?'block':'none'}} onClick={()=>signOutWallet()}>
								Sign Out
							</a>
						</li>
					</UlWrapperRight>
				</NavInner>
			</Wrapper>
		</>
	);


    // Wallet function 
	function connectWallet(){
		if (!isWalletConnected)
			wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
	}
	function signOutWallet(){
        wallet.signOut()
		dispatch(setIsWalletConnected(false))
	}


}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapperRight = styled.ul`
 
`;


