import Countdown from 'react-countdown';


export default function CountDown({ isWhitelist, isPublic, whitelist_date, public_date }) {

    // Countdown renderer  
    const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
    if (completed)
        window.location.replace(window.location.origin + window.location.pathname);
    else
        return (<span>{isWhitelist?'Public Mint : ': 'Whitelist Mint : '}{days * 24 + hours}H {minutes}M {seconds}S </span>);
    };

	// Return countdown
	return countDown();

	// Main Function to render coutndown based on condition  
	function countDown() {
        if(isPublic)
            return (<><div></div></>);
        else if(isWhitelist)
            return (<Countdown date={public_date} renderer={renderer} />);
        return (<Countdown date={whitelist_date} renderer={renderer} />);

	}

}
