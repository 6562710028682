// React Component 
import ReactDOM from 'react-dom';
import store from "./store/store.js";
import { Provider } from "react-redux";
import {  BrowserRouter,  Routes,  Route,} from "react-router-dom";

// App 
import App from './App';

// Global Style 
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.css';


if(document.getElementById('root')){
    ReactDOM.render(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Provider store={store}><App page='launchpad'/></Provider>} />
            </Routes>
        </BrowserRouter>,
        document.getElementById('root')
    );
}

